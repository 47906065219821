.inner-container-game {
    width: 100%;
    height: 100%;
    margin: auto;
    padding-left: 10px;
    padding-right: 10px;
    padding-top: 0px;
    padding-bottom: 0px;
    display: flex;
    flex-direction: column;
    max-width: 1920px;
    justify-content: center;
  }