@import url("https://fonts.googleapis.com/css2?family=Bebas+Neue&display=swap&family=Roboto");
@import url('https://fonts.googleapis.com/css2?family=Press+Start+2P&&family=VT323&display=swap');

:root {
  --primary-color: #000000;
  --secondary-color: #FFFFFF;
  --accent-color: #FED100;
  --background-color: #FFFFFF;
  --sidebar-background-color: #e7e8e8;
  --error-color: "#c74242";
/* 
  --background-color: #e7e8e8; */
  /* ... add more variables as needed ... */
}

html, body, #root, .wrapper {
  height:100%;
}

html,
body {
  padding: 0;
  margin: 0;
  font-family: -apple-system, Bebas Neue, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen,
    Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
}

html, body, #root, .wrapper {
  height:100%;
}

a {
  color: #FED100;
  text-decoration: none;
}

p {
  font-size: 1.2rem;
  /* line-height: 1.5; */
  margin: 1rem 0;
  font-family: 'Bebas Neue';
}

* {
  box-sizing: border-box;
}

html {
  color-scheme: dark;
}
body {
  color: var(--primary-color);
  background-color: var(--background-color);
}

.main {
  min-height: 100%;
  padding: 4rem 0;
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.row{
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.row-center-full {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: auto;
  margin-top: 10px;
  margin-bottom: 10px;
}

.row-no-center{
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
}

.row-space-between {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  align-items: center;
}

.row-space-between-dashboard {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  align-items: center;
  /* height: 29%;
  min-height: 29%; */
  padding-bottom: 15px;
  margin-bottom: 15px;
}

.first-row-space-around-dashboard {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  width: 100%;
  align-items: center;
  /* height: 29%;
  min-height: 29%; */
  padding-bottom: 15px;
  margin-bottom: 15px;
}


.row-space-around-dashboard {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  width: 100%;
  align-items: center;
  margin-bottom: 15px;
}

.row-space-around-dashboard-mobile {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  width: 100%;
  align-items: center;
  margin-bottom: 10px;
}

.row-space-even-honey {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  width: 100%;
  overflow-y: auto;
}

.row-space-around-honey {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  width: 100%;
  overflow-y: auto;
}


.first-row-space-around-honey{
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  width: 100%;
  text-align: left;
}

.header {
  padding-top: 0px;
}

.header-mobile {
  /* padding-top: 10px; */
  width: 100%;
}

.header-row{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 0%;
  padding-left: 0px;
  padding-right: 0px;
  margin: 0%;
}

.header-row-mobile{
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
  padding: 0%;
  margin: 0%;
  width: 100%;
}

.filter-row {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  padding: 7.5px;
  min-width: 300px;
  /* width: 500px; */
}

.filter-row-mobile {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
  padding: 7.5px;
  min-width: 300px;
  /* width: 100dvw; */
}

.page-header-small{
  display: flex;
  justify-content: flex-start;
  align-items: center;
  font-size: 2rem !important;
  font-family: 'Bebas Neue';
  color: #000000;
  margin-top: 5px;
  margin-left: 10px;
  margin-bottom: 5px;
  margin-right: 10px;
}

.page-header-small-mobile{
  display: flex;
  justify-content: flex-start;
  align-items: center;
  font-size: 1.5rem !important;
  font-family: 'Bebas Neue';
  color: #000000;
  margin-top: 5px;
  margin-left: 10px;
  margin-bottom: 5px;
  margin-right: 10px;
}


.page-header{
  display: flex;
  justify-content: flex-start;
  align-items: center;
  font-size: 2.5rem !important;
  font-family: 'Bebas Neue';
  color: #000000;
  margin-top: 5px;
  margin-left: 10px;
  margin-bottom: 5px;
  margin-right: 10px;
}

.page-header-large{
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 5rem !important;
  font-family: 'Bebas Neue';
  color: #000000;
  margin-top: 5px;
  margin-left: 10px;
  margin-bottom: 5px;
  margin-right: 10px;
}

.page-header-mobile{
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  font-size: 1.75rem;
  font-family: 'Bebas Neue';
  color: #000000;
  padding: 10px;
  margin-block-start: 0em;
  margin-block-end: 0em;
}

.factory-search{
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: transparent;
  border: grey 1px solid;
  height: 35px;
  width: 250px;
  border-radius: 15px;
  font-size: 1rem;
  font-family: 'Bebas Neue';
  color: grey;
  margin-top: 0%;
  margin-left: 0%;
  margin-bottom: 0%;
  margin-right: 0px;
  padding-bottom: 0%;
  padding-top: 0%;
  padding-right: 10px;
  padding-left: 10px;
}

.factory-search:active{
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: transparent;
  border: #FED100 1px solid;
  height: 35px;
  width: 250px;
  border-radius: 15px;
  font-size: 1rem;
  font-family: 'Bebas Neue';
  color: #000000;
  padding-left: 10px;
  margin-top: 0%;
  margin-left: 0%;
  margin-bottom: 0%;
  margin-right: 20px;
  padding-bottom: 0%;
  padding-top: 0%;
  padding-right: 0%;
  padding-left: 10px;
}

.factory-search-mobile{
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: transparent;
  border: grey 1px solid;
  height: 35px;
  width: 250px;
  border-radius: 15px;
  font-size: .9rem;
  font-family: 'Bebas Neue';
  color: grey;
  margin-top: 10px;
  margin-left: 0%;
  margin-bottom: 0%;
  margin-right: 0px;
  padding: 10px;
}

.factory-search-mobile:active{
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: transparent;
  border: #FED100 1px solid;
  height: 35px;
  width: 210px;
  border-radius: 15px;
  font-size: .9rem;
  font-family: 'Bebas Neue';
  color: #000000;
  padding-left: 10px;
  margin-top: 0%;
  margin-left: 0%;
  margin-bottom: 0%;
  margin-right: 20px;
  padding-bottom: 0%;
  padding-top: 0%;
  padding-right: 0%;
  padding-left: 10px;
}

.row-between{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  overflow-wrap: break-word;
  width: 100%;
  max-width: 1380px;
}

.row-between-mobile{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  overflow-wrap: break-word;
  width: 100%;
}

.row-around{
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  text-align: center;
  flex-wrap: wrap;
  margin: 0%;
  padding: 0%;
  overflow-wrap: break-word;
  width: 100%;
  max-width: 1380px;
}

.row-center{
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  text-align: center;
  flex-wrap: wrap;
  overflow-wrap: break-word;
  width: 100%;
  max-width: 1380px;
}

.row-center-margin{
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  text-align: center;
  flex-wrap: wrap;
  overflow-wrap: break-word;
  margin-top: 7.5px;
  margin-bottom: 0px;
  width: 100%;
  max-width: 1380px;
}

.column {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.column-center-full-container {
  display: flex;
  flex-direction: column;
  /* height: 100%; */
  width: 100%;
  justify-content: center;
  align-items: center;
  background-color: var(--sidebar-background-color);
  border-radius: 10px;
}

.column-center-full-container-last {
  display: flex;
  flex-direction: column;
  /* height: 100%; */
  width: 100%;
  justify-content: center;
  align-items: center;
  background-color: var(--sidebar-background-color);
  border-radius: 10px;
  padding-bottom: 40px;
}

.column-center-full {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  justify-content: center;
  align-items: center;
  /* margin-top: 10px; */
  margin-bottom: 20px;
  /* padding-bottom: 20px; */
  /* background-color: #fff; */
  border-radius: 10px;
}

.column-between {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  width: 100%;
  padding-right: 5px;
  padding-left: 5px;
}

.asset-numbers {
  font-family: 'Bebas Neue' !important;
  font-size: 3rem !important;
  font-weight: 500 !important;
  color: var(--accent-color);
  margin: 0%;
  padding: 0%;
  display: flex;
  text-align: center !important;
  justify-content: center !important;
  margin-top: auto;
  margin-block-start: 0em;
  margin-block-end: 0em;
}

.asset-type {
  overflow-wrap: normal;
  font-family: 'Bebas Neue' !important;
  font-size: 1.25rem !important;
  font-weight: 400 !important;
  color: var(--primary-color);
  margin: 0%;
  padding: 0%;
  display: flex;
  text-align: center !important;
  justify-content: center !important;
  margin-top: auto;
  margin-bottom: auto;
}

.honeyBalanceBlack {
  color: var(--primary-color) !important;
  font-family: 'Bebas Neue' !important;
  font-size: 2rem !important;
  font-weight: 500 !important;
  margin: 0%;
  padding: 0%;
  display: flex;
  text-align: right !important;
  justify-content: end !important;
}

.honeyBalance {
  color: var(--accent-color) !important;
  font-family: 'Bebas Neue' !important;
  font-size: 2rem !important;
  font-weight: 500 !important;
  margin: 0%;
  padding: 0%;
  padding-left: 5px;
  display: flex;
  text-align: left !important;
  justify-content: start !important;
}

.col-no-space {
  display: flex;
  flex-direction: column;
  justify-content: center;
  /* justify-content: space-around; */
  margin: 0%;
  padding: 0%;
  height: 100%;
}
.col{
  display: flex;
  flex-direction: column;
}

.col-margin{
  display: flex;
  flex-direction: column;
  margin-left: 5px;
  height: 100%;
  justify-content: center;
}

.tabs-image {
  width: 50px;
  height: 50px;
  object-fit: cover;
  border-radius: 10px;
  /* margin: 0%;
  padding: 0%;
  justify-content: center;
  align-items: center; */
}

.SidebarWrapper .MuiDrawer-paper {
  overflow-y: scroll;
  scrollbar-width: none; /* Firefox */
  -ms-overflow-style: none; /* IE 10+ */
}

.learnMoreBtn {
  background: none !important;
  border: none !important;
  color: #000000 !important;
  opacity: .5;
  text-align: center;
  font-size: 1rem;
  margin: 0;
  padding: 0;
  padding-right: 10px;
  cursor: pointer;
}

.learnMore {
  background: none !important;
  border: none !important;
  color: #000000 !important;
  opacity: .5;
  text-align: center;
  font-size: 1rem;
  margin: 0;
  padding: 0;
  padding-right: 10px;
}

.button-with-icon {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  height: 35px
}

.optionsRow{
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
  margin-top: 15px;
  margin-bottom: 15px;
  width: 100%;
}

.pointer-icon {
  cursor: pointer;
  opacity: .67;
}

.notifications {
  /* cursor: pointer; */
  margin-top: 5px;
  padding: 1px;
  justify-content: center;
  align-items: center;
  width: auto !important;
  height: auto !important; 
}

.icon {
  /* cursor: pointer; */
  opacity: .6;
}

.center-flexbox {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  /* background-color: #fff; */
  border-radius: 15px;
  margin: 0px;
  padding: 4px;
  
}

.app-container {
  width: 100%;
  height: 100% !important;
  overflow-x: auto;
  overflow-y: auto;
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.container {
  opacity: 1 !important;
  font-weight: 300;
  max-width: 250px !important;
}

.inner-container {
  width: 100%;
  height: 100%;
  margin: auto;
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 0px;
  padding-bottom: 0px;
  display: flex;
  flex-direction: column;
  max-width: 1380px;
  justify-content: center;
  -ms-overflow-style: none;
  scrollbar-width: none;
}

body::-webkit-scrollbar{
  display: none;
}

.inner-container::-webkit-scrollbar{
  display: none;
}

.inner-container-mint {
  width: 100%;
  height: 100%;
  margin: auto;
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 0px;
  padding-bottom: 0px;
  display: flex;
  flex-direction: column;
  max-width: 1380px;
  justify-content: center;
}


.inner-container-mint-mobile {
  width: 100%;
  height: 100%;
  margin: 0;
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 0px;
  padding-bottom: 0px;
  display: flex;
  flex-direction: column;
}

.inner-container-mobile {
  width: 100%;
  height: 100%;
  margin: 0;
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 0px;
  padding-bottom: 0px;
  overflow-y: auto;
  /* padding-bottom: 60px; */
  display: flex;
  flex-direction: column;
  background-color: var(--sidebar-background-color);
}

.factory-inner-container {
  width: 100%;
  height: 100%;
  max-width: 1380px;
  margin: auto;
  padding-bottom: 0;
  display: flex;
  flex-direction: column;
  padding-top: 10px;
  padding-left: 20px;
  padding-right: 20px;

  /* justify-content: center;
  align-items: center; */
}

.factory-inner-container-mobile {
  width: 100%;
  height: 100%;
  margin-top: 0px;
  padding: 0px;
  overflow-y: auto;
  /* justify-content: center;
  align-items: center; */
}

.header-title {
  font-weight: 300 !important;
  font-size: 14px;
}

.connectWalletOverride {
  width: 215px !important;
  border: 1px solid var(--primary-color) !important;
}

.connectWalletOverride-Mobile {
  width: 225px !important;
  min-width: 225px !important;
  border: 1px solid var(--primary-color) !important;
}

.info-card {
  background-color: #fff;
  border-radius: 10px;
  justify-content: left;
  flex-direction: column;
  /* margin-left: 10px;
  margin-right: 10px; */
  margin-top: 10px;
  margin-bottom: 10px;
  /* padding-top: 5px; */
}

.info-card__title {
  font-size: 1.3rem;
  font-weight: 400;
  margin: 0;
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 0px;
  padding-bottom: 0px;
  font-family: 'Bebas Neue';
}

.info-card__description {
  font-size: .7rem;
  font-family: 'Roboto', sans-serif;
  font-weight: 300;
  margin: 0;
  padding-top: 5px;
  padding-left: 10px;
  padding-right: 10px;
  padding-bottom: 5px;
  width: 100%;
  white-space: pre-wrap;
  text-align: left;
}

.ptr-override {
  overflow: hidden !important;
}

.footer-card {
  justify-content: right;
  align-items: end;
  display: flex;
  margin-right: 5px;
  margin-bottom: 5px;
  height: 35px
}

.partnerImage {
  width: 80px !important;
  height: 80px !important;
  object-fit: contain !important;
  cursor: pointer;
}

.mainLogo {
  width: 100%;
  height: 100%;
  object-fit: contain;
  cursor: pointer;
}

.mainLogo-Mobile {
  width: 80%;
  height: 100%;
  object-fit: contain;
  cursor: pointer;
}

.hubLogo {
  width: 30%;
  height: 100%;
  padding-left: 5%;
  margin-right: 0px;
  object-fit: contain;
  aspect-ratio: 1/1;
  cursor: pointer;
}

.partnerLogo {
  margin: 0px;
  padding: 0%;
  object-fit: contain;
  cursor: pointer;
  width: 79px;
  height: 79px;
}

.connect {
  width: 230px;
  margin-right: 5%;
}

.row-logo{
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  width: 100%;
  margin: 0;
  padding: 0;
}

.row-left {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  width: 100%;
}

.bold-text {
  /* color: var(--accent-color); */
  font-weight: 500;
}

.accent-text {
  color: var(--accent-color);
  font-weight: 500;
}

.accessDeniedImage {
  object-fit: contain;
  max-width: 300px;
  transform: rotateY(0deg) rotate(-20deg); /* needs Y at 0 deg to behave properly*/
  transition: transform 2s;
  position: absolute;
  top: 50px;
  left: 250px;
  z-index: 0;
}

.accessDeniedImage-mobile {
  object-fit: contain;
  max-width: 300px;
  transform: rotateY(0deg) rotate(-20deg); /* needs Y at 0 deg to behave properly*/
  transition: transform 2s;
  padding-top: 10px;
  position: relative;
  z-index: 1;
}

.addPadding {
  padding-top: 100px;
}

body::-webkit-scrollbar{
  display: none;
}

.router-container::-webkit-scrollbar{
  display: none;
}

.router-container {
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.ptr-override::-webkit-scrollbar{
  display: none;
}

.ptr-override {
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.app-container::-webkit-scrollbar{
  display: none;
}
