/* .leftDrawer {
    width: 240px;
    flex-shrink: 0;
    /* background-color: var(--background-color, #e7e8e8); */
/* background-color: var(--background-color);

}  */

@import url('https://fonts.googleapis.com/css?family=Lato:100&display=swap');

.dashboard-inner-container {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  /* padding-top: 10px;
  padding-bottom: 10px; */
}

.app-container {
  width: 100%;
  height: 100% !important;
}

.comingSoon {
  color: var(--accent-color);
  font-size: 7rem !important;
  font: 'Bebas Neue';
  text-align: center;
  z-index: 1;
  font-family: 'Bebas Neue';
}

.comingSoon-Mobile {
  color: var(--accent-color);
  font-family: 'Bebas Neue';
  font-size: 3.5rem !important;
  font: 'Bebas Neue';
  text-align: center;
  z-index: 1;
  height: fit-content;
  margin-block-start: 0em;
  margin-block-end: 0em;
}

.comingSoonBlack {
  color: #000000;
  font-size: 7rem !important;
  font: 'Bebas Neue';
  text-align: center;
  z-index: 1;
  font-family: 'Bebas Neue';
  margin-right: 5px;
}

.comingSoonBlack-Mobile {
  color: #000000;
  font-size: 3.5rem !important;
  font: 'Bebas Neue';
  text-align: center;
  z-index: 1;
  font-family: 'Bebas Neue';
  height: fit-content;
  margin-block-start: 0em;
  margin-block-end: 0em;
  margin-right: 5px;
}



.main {
  min-height: 100%;
  padding: 4rem 0;
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.title a {
  color: #FED100;
  text-decoration: none;
}

.title a:hover,
.title a:focus,
.title a:active {
  text-decoration: underline;
}

.title {
  margin: 0;
  line-height: 1.15;
  font-size: 4rem;
}

.title,
.description {
  text-align: center;
}

.metadata {
  color: #000000;
  margin: 0%;
  margin-bottom: 5px;
  padding: 0%;
  padding-left: 3%;
  text-align: left;
  opacity: .5;
}

.metadata-title {
  color: #000000;
  margin: 0%;
  margin-top: 5px;
  padding: 0%;
  padding-left: 3%;
  text-align: left;
  font-weight: 400;
  font-size: 1.5rem;
  font-family: 'Bebas Neue';
}

.description {
  margin-top: 2rem;
  margin-bottom: 1rem;
  line-height: 1.5;
  font-size: 1.15rem;
}

.code {
  background: #fafafa;
  border-radius: 5px;
  padding: 0.75rem;
  font-size: 1.1rem;
  font-family: Menlo, Monaco, Lucida Console, Liberation Mono, DejaVu Sans Mono,
    Bitstream Vera Sans Mono, Courier New, monospace;
}

.grid {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  max-width: 1200px;
}

.card {
  /* box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px, rgba(10, 37, 64, 0.35) 0px -2px 6px 0px inset; */
  /* box-shadow: rgba(0, 0, 0, 0.25) 0px 54px 55px, rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px, rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px; */
  /* background-color: white;*/
  /* border: none; */
  background-color: white !important;
  color: grey;
  border: 3px solid transparent;
  border-color: transparent;
  margin: 0%;
  padding-top: 0%;
  padding-left: 0%;
  padding-right: 0%;
  padding-bottom: 0%;
  text-align: left;
  border-radius: 10px;
  /* transition: color 0.15s ease, border-color 0.15s ease; */
  max-width: 250px;
  position: relative;
  display: flex;
  flex-direction: column;
}

.card img {
  display: block;
  padding: 0%;
  margin: 0%;
}

.card:hover,
.card:focus,
.card:active {
  background-color: white !important;
  color: #FED100;
  /* transition: color 0.15s ease, border-color 0.15s ease; */
}


.card img:hover,
.card img:focus,
.card img:active {
  background-color: white !important;
  color: #FED100;
  border: 3px solid transparent;
  border-color: #FED100;
  transition: color 0.15s ease, border-color 0.15s ease;
}

.card-selected img {
  /* box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px, rgba(10, 37, 64, 0.35) 0px -2px 6px 0px inset; */
  /* box-shadow: rgba(0, 0, 0, 0.25) 0px 54px 55px, rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px, rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px; */
  /* background-color: white;*/
  /* border: none; */
  background-color: white !important;
  color: var(--accent-color);
  border: 5px solid var(--accent-color);
  ;
  border-color: var(--accent-color);
  ;
  transition: color 0.15s ease, border-color 0.15s ease;
  margin: 0%;
  padding-top: 0%;
  padding-left: 0%;
  padding-right: 0%;
  padding-bottom: 0%;
  text-align: left;
  border-radius: 10px;
  transition: color 0.15s ease, border-color 0.15s ease;
  max-width: 250px;
  position: relative;
  display: flex;
  flex-direction: column;
  /* filter: grayscale(90%); */
  filter: brightness(30%);
}

.card-dashboard {
  /* box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px, rgba(10, 37, 64, 0.35) 0px -2px 6px 0px inset; */
  /* box-shadow: rgba(0, 0, 0, 0.25) 0px 54px 55px, rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px, rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px; */
  /* background-color: white;*/
  /* border: none; */
  background-color: white !important;
  color: grey;
  border: 3px solid transparent;
  border-color: transparent;
  margin: 0%;
  padding-top: 0%;
  padding-left: 0%;
  padding-right: 0%;
  padding-bottom: 0%;
  text-align: left;
  border-radius: 10px;
  /* transition: color 0.15s ease, border-color 0.15s ease; */
  /* max-width: 200px; */
  position: relative;
  display: flex;
  flex-direction: column;
}

.card-dashboard img {
  display: block;
  padding: 0%;
  margin: 0%;
}

/* .card-dashboard:hover,
.card-dashboard:focus,
.card-dashboard:active {
  background-color: white !important;
  color: #FED100;
  /* transition: color 0.15s ease, border-color 0.15s ease; 
} */


/* .card-dashboard img:hover,
.card-dashboard img:focus,
.card-dashboard img:active {
  background-color: white !important;
  color: #FED100;
  border: 3px solid transparent;
  border-color: #FED100;
  transition: color 0.15s ease, border-color 0.15s ease;
} */

.info-card-dashboard  {
  background-color: #fff;
  width: 100%;
  border-radius: 10px;
  justify-content: center;
  flex-direction: column;
  height: 100%;
  overflow-y: auto;
}

.info-card__title-dashboard  {
  font-size: 1.3rem;
  font-weight: 400;
  margin: 0;
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 0px;
  padding-bottom: 0px;
}

.info-card__description-dashboard {
  font-size: .7rem;
  font-family: 'Roboto', sans-serif;
  font-weight: 300;
  margin: 0;
  padding-top: 5px;
  padding-left: 10px;
  padding-right: 10px;
  padding-bottom: 5px;
  width: 100%;
  white-space: pre-wrap;
  text-align: left;
}

.column-between-dashboard {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  text-align: center;
  height: 100%;
  width: 32%;
}

.column-between-honey {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  width: 32%;
  align-items: center;
  margin-left: 5px;
  margin-right: 5px;
}


.column-center-dashboard {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  height: 100%;
  width: 32%;
}

.honey-earnings-dashboard {
  display: flex;
  font-size: 3.75rem !important;
  margin: 0;
  padding: 0;
  margin-block-start: 0em;
  margin-block-end: 0em;
}

.page-header-dashboard{
  display: flex;
  justify-content: flex-start;
  align-items: center;
  font-size: 2rem !important;
  font-family: 'Bebas Neue';
  color: #000000;
  margin: 0;
  padding: 0;
}

.perDay {
  display: flex;
  font-size: .8rem;
  text-align: right;
  margin: 0;
  padding: 0;
  margin-block-start: 0em;
  margin-block-end: 0em;
  /* align-items: end; */
}

.perDay-mobile {
  display: flex;
  font-size: .65rem;
  text-align: right;
  margin: 0;
  padding: 0;
  margin-block-start: 0em;
  margin-block-end: 0em;
  /* align-items: end; */
}

.honey-earnings-title {
  display: flex;
  font-size: 1.2rem !important;
  font-weight: 500;
  margin: 0;
  padding: 0;
  padding-bottom: 15px;
  margin-block-start: 0em;
  margin-block-end: 0em;
  text-align: center;
  justify-content: center;
}

.honeyImage {
  width: 90%;
  height: 75%;
  /* padding-bottom: 5px; */
  margin: auto;
  object-fit: contain;
}

.honeyImage-mobile {
  width: 100px;
  height: 100px;
  margin: auto;
  object-fit: contain;
}

.plushyImage {
  width: 100%;
  height: 100%;
  margin: auto;
  object-fit: cover;
  border-radius: 10px;
}

.plushyImage-mobile {
  width: 170px;
  height: 100%;
  /* margin-bottom: 10px; */
  margin: auto;
  padding: 2.5px;
  border-radius: 10px;
  object-fit: cover;
}


.small-text {
  font-size: .75rem !important;
  font-family: 'Roboto', sans-serif !important;
  font-weight: 300 !important;
  margin: 0;
  padding: 0;
}

.asset-numbers-dashboard {
  font-family: 'Bebas Neue' !important;
  font-size: 3rem !important;
  font-weight: 500 !important;
  color: var(--accent-color);
  margin: 0%;
  padding: 0%;
  display: flex;
  text-align: center !important;
  justify-content: center !important;
  margin-top: auto;
  margin-block-start: 0em;
  margin-block-end: 0em;
}

.asset-type-dashboard {
  overflow-wrap: normal;
  font-family: 'Bebas Neue' !important;
  font-size: 1.7rem !important;
  font-weight: 400 !important;
  color: var(--primary-color);
  margin: 0%;
  padding: 0%;
  display: flex;
  text-align: center !important;
  justify-content: center !important;
  margin-top: 0;
  margin-bottom: 0;
  margin-block-start: 0em;
  margin-block-end: 0em;
}

.honeyBalanceBlack-dashboard {
  color: var(--primary-color) !important;
  font-family: 'Bebas Neue' !important;
  font-size: 2.5rem !important;
  font-weight: 500 !important;
  margin: 0%;
  padding: 0%;
  display: flex;
  text-align: right !important;
  justify-content: end !important;
}

.honeyBalance-dashboard {
  color: var(--accent-color) !important;
  font-family: 'Bebas Neue' !important;
  font-size: 2.5rem !important;
  font-weight: 500 !important;
  margin: 0%;
  padding: 0%;
  padding-left: 5px;
  display: flex;
  text-align: left !important;
  justify-content: start !important;
}

.col-large-dashboard {
  display: flex;
  flex-direction: row;
  justify-content: center;
  /* justify-content: space-around; */
  margin: 0%;
  padding: 0%;
  height: 100%;
  width: 80%;
}

.half-col {
  height: 100%;
  width: 50%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.dashboard-button {
  width: 100%;
  margin-bottom: 5px;
  padding: 8px !important;
  min-height: 45px;
}

.logo {
  height: 1em;
  margin-left: 0.5rem;
}

@media (max-width: 600px) {
  .grid {
    width: 100%;
    flex-direction: column;
  }
}

/* .card,
  .footer {
    border-color: #222;
  } */
.code {
  background: #111;
}

.logo img {
  filter: invert(1);
}

.column-container {
  flex-direction: row;
  display: flex;
}

.row {
  flex-direction: row;
  display: flex;
  width: 100%;
}

.stats {
  color: black;
  font-size: 48px;
  font-weight: 600;
  font-family: 'Bebas Neue';
  margin: 0%;
  padding: 5px;
  text-align: end;
  line-height: 1;
}

.stats-name {
  font-size: 1.5rem;
  font-family: 'Bebas Neue';
  overflow-wrap: break-word;
  text-align: left;
  margin: 0%;
  padding-left: 5px;
  padding-right: 0px;
  font-weight: 300;
  line-height: 1;

}

.stats-col {
  display: flex;
  flex-direction: row;
  width: 33.33%;
  margin-right: 10px;
  justify-content: center;
  align-items: center;
}

.large-left-column {
  width: 85%;
  text-align: left;
  flex-direction: column;
  display: flex;
  background-color: white;
}

.small-right-column {
  width: 15%;
  text-align: right;
  display: flex;
  margin: auto;
  vertical-align: middle;
  background-color: white;
  padding-right: auto;
  padding-left: auto;
  justify-content: flex-end;
  align-items: center;
}