/* src/components/StatusBar.css */

.status-bar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #000;
    color: #0f0; /* Green text */
    font-family: 'Press Start 2P', cursive;
    /* font-family: 'VT323', monospace; */
    padding: 10px;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    z-index: 999; /* Ensure it's above other content */
  }
  
  .status-item {
    margin-right: 20px;
  }
  
  /* Add additional styles as needed */
  