.ted-honey-claim-row {
    width: 95%;
    /* height: 300px; */
    border-radius: 10px;
    background-color: var(--accent-color);
    align-items: center;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    margin-top: 15px;
    margin-bottom: 15px;
    display: flex;
    flex-direction: row;
}

.ted-honey-claim-col {
    width: 95%;
    border-radius: 10px;
    background-color: var(--accent-color);
    align-items: center;
    margin: 0%;
    padding: 0%;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    flex-direction: column;
    margin-top: 15px;
}

.column-center {
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 100%;
    justify-content: space-evenly;
    align-items: flex-start;
    border-radius: 10px;
  }